import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DomSanitizer } from '@angular/platform-browser';
import {typewriter} from 'typewriter-effect/dist/core';
gsap.registerPlugin(ScrollTrigger);
import { AngularTypewriterEffectModule } from 'angular-typewriter-effect';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  list = ['Mobile Application', 'Web Design and Maintainence', 'Ecommerce Website', 'Inventory Management', 'Enterprise Resource planning'];

  ngOnInit(): void {
  }



  initSrollHeading(): void {
  }
}
