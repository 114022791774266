<section class="services cd-section" id="solutions">
  <div class="container">
    <h2>Solutions</h2>
    <div class="row" id="Erp">
      <div class="col-md-8 col-lg-8 col-md-8 img_parent_parent">
        <div class="img_append">
          <span class="material-icons"> business </span>
          <h4>Enterprise resource planning</h4>
        </div>
        <p>
          Konark IT offers wide range of IT solutions covering every aspect of
          Business in ERP development.
        </p>
        <p>
          Konark IT ERP cover Functions modules Accounting, procurement
          including Import, Production Planning, Sales including Export,
          Costing, Inventory management, supply chain operations, Fixed Assets,
          Multi currency, Cash Flow, Risk management, compliance, Document
          Management system (DMS) and robust MIS.
        </p>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 img_parents_parents">
        <figure class="services_figure img_parent">
          <img
            src="assets/images/service/ERP-Software.jpg"
            alt="Erp development"
          />
        </figure>
      </div>
    </div>
    <div class="row" id="web_development">
      <div class="col-xl-3 col-lg-3 col-md-3 parent">
        <figure class="services_figure parent_img">
          <img src="assets/images/service/web.png" alt="web development" />
        </figure>
      </div>
      <div class="col-md-8 col-lg-8 col-md-8 parent_child">
        <div class="text_append">
          <span class="material-icons"> language </span>
          <h4>Web design</h4>
        </div>
        <p>
          We not design web site of company but we built Brand, website will be
          your brand ambassador, we build a unique website to convert visitors
          into customers, We understand product, service and industry of client
          before designing web site. We have design and contain writing talent.
        </p>
        <p>
          The look and feel of your website is paramount in determining whether
          visitor stay on it or leave. Our designs have great UI-User Interface
          which result in great UX-User Experience.
        </p>
        <p>
          designs are device friendly across all Platforms and screen sizes.
          websites are built with features to help your website rank higher.
        </p>
      </div>
    </div>
    <div class="row" id="app_development">
      <div class="col-md-8 col-lg-8 col-md-8 img_parent_parent">
        <div class="img_append">
          <span class="material-icons"> smartphone </span>
          <h4>Mobile Application</h4>
        </div>
        <p>
          Konark IT expertise and experience in building top class futuristic
          mobile application with rich UX, UI design.The ability of your mobile
          app to rise above the competition would depend on the quality of user
          experience it provides.
        </p>
        <p>
          The ability of your mobile app to rise above the competition would
          depend on the quality of user experience it provides. As the top
          mobile development agency in India with considerable experience and
          expertise, we ensure your mobile app incorporates the right features
          and functionalities to deliver the best user experience.
        </p>
        <p>
          Are you finding it difficult to choose an appropriate platform for
          your business app? Trust the expertise of KonarkIT Solutions to
          suggest you the right platform by analyzing your target demography and
          competitors. As a premier mobile app development service provider, we
          build feature-rich, highly interactive, and secure apps for various
          platforms
        </p>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 img_parents_parents">
        <figure class="services_figure img_parent">
          <img
            src="assets/images/service/app_development.png"
            alt="App Development"
          />
        </figure>
      </div>
    </div>
    <div class="row" id="business">
      <div class="col-xl-3 col-lg-3 col-md-3 parent">
        <figure class="services_figure parent_img">
          <img
            src="assets/images/service/business_anlysis.jpg"
            alt="Business Analysis"
          />
        </figure>
      </div>
      <div class="col-md-8 col-lg-8 col-md-8 parent_child">
        <div class="text_append">
          <span class="material-icons"> language </span>
          <h4>Data and Business Analytics</h4>
        </div>
        <p>
          Konark IT provides business community a unique solution which is
          combination of Data Analytics & Business Intelligence. Data sourcing
          from multiple data source like ERP, SRM, HRM, CRM, Online partner,
          external data input and Social Media are presented to users in IMPACT
          form.
        </p>
        <p>
          Data sourcing from multiple data base like ERP, SRM, HRM, CRM, Online
          partner, external data input and Social Media.
        </p>
        <p>
          It’s impossible to deny the importance of data in several industries,
          but that data can get overwhelming if it isn’t properly managed. The
          problem is that managing and extracting valuable insights from all
          this data needs exceptional data collecting, which makes data
          ingestion vital. We have capability and expertise to do for you and
          put impactful intelligent data in right time and right way.
        </p>
      </div>
    </div>
  </div>
</section>
