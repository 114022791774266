<header>
  <div class="container">
    <div class="header-social">
      <div>
        <a href="mailto:info@konarkit.in" title="Email us"
          ><span class="material-icons"> email </span>info@konarkit.in</a
        >
      </div>
      <ul class="row header-social-media">
        <li class="col">
          <a
            href="https://www.facebook.com/KonarkITofficial/?view_public_for=103837774845612"
            title="Facebook"
            target="_blank"
          >
            <i class="fa fa-facebook"></i>
          </a>
        </li>
        <li class="col">
          <a
            href="https://www.instagram.com/konarkit/
            "
            title="Instagram"
            target="_blank"
          >
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </li>
        <li class="col">
          <a
            href="https://twitter.com/KonarkIt"
            title="Twitter"
            target="_blank"
          >
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>
        </li>

        <li class="col">
          <a
            href="https://www.linkedin.com/company/konarkit"
            title="Linkedin"
            target="_blank"
          >
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </div>

    <nav class="navbar navbar-expand-lg navbar-dark default-color">
      <a href="#FIXME" title="Konark" class="konark">
        <img src="assets/images/Logo/konarkit2.png" alt="Konark It" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent-333"
        aria-controls="navbarSupportedContent-333"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="material-icons menu"> menu </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent-333">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" href="#"
              >Home
              <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about_us">About us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#solutions">Solutions</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#career">Carrer</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact us</a>
          </li>
        </ul>
      </div>
    </nav>

    <!--Navbar End here -->
  </div>
</header>

<app-snackbar
  [message]="message"
  [msgType]="msgType"
  [event]="event"
></app-snackbar>
<div class="form-box">
  <div class="form-box-title">
    <span class="material-icons"> help </span>
    <p>Ask for solution</p>
  </div>
  <form
    class="form"
    (ngSubmit)="onFinalDetailsSubmit($event)"
    [formGroup]="enquiryForm"
  >
    <span class="material-icons close">cancel</span>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        id="name"
        aria-describedby="Name"
        placeholder="Enter Your Name"
        formControlName="name"
        autocomplete="off"
        [class.is-invalid]="
          enquiryForm.get('name').invalid && enquiryForm.get('name').touched
        "
      />
      <div
        *ngIf="
          enquiryForm.get('name').invalid && enquiryForm.get('name').touched
        "
      >
        <small
          class="text-danger"
          *ngIf="enquiryForm.get('name').errors?.required"
          >Name is required.</small
        >
      </div>
    </div>
    <div class="form-group">
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter Your Email"
        formControlName="mailId"
        [class.is-invalid]="
          enquiryForm.get('mailId').invalid && enquiryForm.get('mailId').touched
        "
        autocomplete="off"
      />
      <div
        *ngIf="
          enquiryForm.get('mailId').invalid && enquiryForm.get('mailId').touched
        "
      >
        <small
          class="text-danger"
          *ngIf="enquiryForm.get('mailId').errors?.required"
          >Email Id is required.</small
        >
        <small
          class="text-danger"
          *ngIf="enquiryForm.get('mailId').errors?.pattern"
          >Invalid Email Id.</small
        >
      </div>
    </div>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        id="number"
        placeholder="Enter Your Mobile Number"
        formControlName="mobileNo"
        [class.is-invalid]="
          enquiryForm.get('mobileNo').invalid &&
          enquiryForm.get('mobileNo').touched
        "
        autocomplete="off"
      />

      <div
        *ngIf="
          enquiryForm.get('mobileNo').invalid &&
          enquiryForm.get('mobileNo').touched
        "
      >
        <small
          class="text-danger"
          *ngIf="enquiryForm.get('mobileNo').errors?.required"
          >Mobile Number is required.</small
        >
        <small
          class="text-danger"
          *ngIf="enquiryForm.get('mobileNo').errors?.pattern"
          >Invalid Mobile Number.</small
        >
      </div>
    </div>
    <div class="form-group">
      <textarea
        name="Message"
        id=""
        cols="30"
        rows="4"
        class="form-control"
        formControlName="message"
        autocomplete="off"
        [class.is-invalid]="
          enquiryForm.get('message').invalid &&
          enquiryForm.get('message').touched
        "
      >
        Message
    </textarea
      >
      <div
        *ngIf="
          enquiryForm.get('message').invalid &&
          enquiryForm.get('message').touched
        "
      >
        <small
          class="text-danger"
          *ngIf="enquiryForm.get('message').errors?.required"
          >Message is required.</small
        >
      </div>
    </div>
    <button
      type="submit"
      class="btn btn-primary submit"
      [disabled]="!enquiryForm.valid"
    >
      Submit
    </button>
  </form>
</div>

<!-- Modal content -->
<!-- <div id="wish" class="modal">
  <div class="modal-content wish-content">
    <span class="close">&times;</span>
    <div class="modal-body">
      <figure>
        <img src="assets/images/wish.png" alt="" />
      </figure>
    </div>
  </div>
</div> -->
