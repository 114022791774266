import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  FormControl,
  FormBuilder,
  FormsModule,
  Validators,
} from '@angular/forms';
import { mail } from '../shared/global/globalUrl';
import { KonarkServiceService } from '../shared/service/konark-service.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss'],
})
export class CareerComponent implements OnInit {
  careerForm: FormGroup;
  url = '';

  constructor(
    private formBuilder: FormBuilder,
    private _data: KonarkServiceService
  ) {}

  ngOnInit(): void {
    this.loadCareerform();
  }

  loadCareerform() {
    this.careerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      mailId: ['', [Validators.required]],
      mobileNo: ['', [Validators.required]],
      message: ['', [Validators.required]],
      fileUploader: [null],
    });
  }

  selectFile(event) {
    var selectedFile = <File>event.target.files[0];
    const formdata: FormData = new FormData();
    formdata.append('fileUploader', selectedFile, selectedFile.name);
    this.careerForm.get('fileUploader').setValue(formdata);
  }

  onCareerDetailsSubmit() {
    console.log(this.careerForm.value);
    this.url = mail.mail;
    this._data.post(this.url, this.careerForm.value).subscribe(
      (res) => {
        console.log(res);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
