import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewChildren,
} from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { toArray } from 'rxjs/operators';
gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss'],
})
export class WhatWeDoComponent implements OnInit {
  @ViewChildren('bgloaded') Feature: ElementRef<any>;
  @ViewChild('liContainer', { static: false }) container: ElementRef<any>;
  @ViewChild('ul', { static: false }) ul: ElementRef<any>;
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    console.log('Features', this.Feature);
    this.featureAnimation();
  }

  featureAnimation() {
    gsap.from(this.Feature.nativeElement, {
      scrollTrigger: {
        trigger: this.container.nativeElement,
        markers: true,
      }, // start the animation when ".box" enters the viewport (once)
      y: 60,
      duration: 3,
      scaleX: 3,
      rotate: 70,
    });
  }
}
