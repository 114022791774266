import { Component } from '@angular/core';
import * as Modernizr from 'modernizr';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'konark-it';
  location = '';
  constructor(private _location: Location) {}

  slides = [
    {
      img: 'assets/images/service/analytic.jpg',
      content: '<h3>data and business analytic</h3>',
    },
    {
      img: 'assets/images/service/analytic.jpg',
      content: '<h3>data and business analytic</h3>',
    },
    {
      img: 'assets/images/service/analytic.jpg',
      content: '<h3>data and business analytic</h3>',
    },
    {
      img: 'assets/images/service/analytic.jpg',
      content: '<h3>data and business analytic</h3>',
    },
  ];

  slideConfig = { slidesToShow: 4, slidesToScroll: 4 };

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }
  ngOnInit(): void {
    this.location = this._location.path();
    console.log(this.location);
  }
}
