<main>
  <section id="section1" class="cd-section banner" #sectionOne>
    <div class="container">
      <!-- <div class="video" [innerHTML]="videoTag"></div> -->
      <div class="">
        <div class="row banner_slider">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <h2>
                Next-Generation
                <h3 style="font-size: 30px">ERP system</h3>
              </h2>
              <!-- <p>
                Ultimate ERP software, a distinguished vendor of enterprise
                resource planning applications that support business builders to
                drive progress.
              </p> -->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <figure>
                <img src="assets/images/erp_img.png" alt="Erp" />
              </figure>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <h2>
                Dymanic
                <h3 style="font-size: 30px">Website Design</h3>
              </h2>
              <!-- <p>
                Ultimate ERP software, a distinguished vendor of enterprise
                resource planning applications that support business builders to
                drive progress.
              </p> -->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <figure>
                <img src="assets/images/web_design.png" alt="Website" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
