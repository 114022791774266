import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KonarkServiceService {
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      AuthorizationKey:
        'HdBm3PfN+yGiwUI/T1/RQsKVUNvGdsQEeXTONwOuZYwxIGEVSOkcw+uMyHCWbTZhPM5d9CDRkRniUALZs7MPbl1gtCKPXpGxmvrqBGtOz4ZBjW8azeAgm6OZw1keP8Psp0X+xsSloyZabXw9U/rckyrzRv0MswaQmms7GSxZyfg=',
    }),
  };

  constructor(private _httpClient: HttpClient) {}

  post(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);

    return this._httpClient.post(url, body, this.headers);
  }
}
