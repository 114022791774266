<section id="contact" class="cd-section contact_us">
  <div class="container">
    <!-- <h2>Ask for solution</h2> -->
    <!-- <div style="width: 100%"><iframe width="100%" height="500" frameborder="2" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=D-701,%20Rajeshwar%20CHS%20Ltd,%20JVLR,%20Near%20shyam%20nagar%20talao,%20Jogeshwari%20%E2%80%93%20(e),%20Mumbai%20%E2%80%93%20400%20060.,%20Dublin,%20Ireland+(Konark%20IT)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.maps.ie/route-planner.htm"></a></div> -->
    <h2>Contact us</h2>
    <div class="connect row">
      <div class="marker connect_content col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <i class="fa fa-map-marker" aria-hidden="true"></i>
        <div class="address">
          <h6>Register Office:</h6>
          <p>
            D-701, Rajeshwar CHS Ltd, JVLR, Near shyam nagar talao, Jogeshwari –
            (e), Mumbai – 400 060.
          </p>
        </div>
      </div>
      <div class="marker connect_content col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <i class="fa fa-map-marker" aria-hidden="true"></i>
        <div class="address">
          <h6>Corporate Office:</h6>
          <p>
            89 Mistry Industrial Complex MIDC Cross Road "A", MIDC, Andheri -
            East, Mumbai - 400 090.
          </p>
        </div>
      </div>
    </div>
    <div class="connect row">
      <div
        class="connect_email connect_content col-xl-4 col-lg-4 col-md-4 col-sm-4"
      >
        <div class="contact-box row">
          <i class="fa fa-envelope" aria-hidden="true"></i>
          <div class="contact-box-content">
            <a href="mailto:info@konarkit.in" title="Mail us">
              info@konarkit.in</a
            >
          </div>
        </div>
      </div>
      <div class="WhatsApp connect_content col-xl-4 col-lg-4 col-md-4 col-sm-4">
        <div class="contact-box row">
          <i class="fa fa-whatsapp" aria-hidden="true"></i>
          <div class="contact-box-content">
            <a
              href="https://api.whatsapp.com/send?phone=919892019991&text=Hi,%20Ask%20for%20Solution"
              title="WhatsApp Us"
              >98 9201 9991</a
            >
          </div>
        </div>
      </div>
      <div class="mobile connect_content col-xl-4 col-lg-4 col-md-4 col-sm-4">
        <div class="contact-box row">
          <i class="fa fa-mobile" aria-hidden="true"></i>
          <div class="contact-box-content">
            <a href="tel:9892019991">98 9201 9991</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="col-xl-8 col-md-8 contact_right">
        <h2>Get in Touch</h2>
        <p>Feel free to drop us a line below !</p>
        <form>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="name"
              aria-describedby="Name"
              placeholder="Enter Your Name"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Your Email"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="number"
              placeholder="Enter Your Mobile Number"
            />
          </div>
          <div class="form-group">
            <textarea
              name="Message"
              id=""
              cols="30"
              rows="4"
              class="form-control"
            >
Message</textarea
            >
          </div>
          <button type="submit" class="btn btn-primary submit">Submit</button>
        </form>
      </div> -->
</section>
<!-- cd-section -->
