<section id="section6" class="footer">
  <div class="container">
    <div class="row konark-footer-content">
      <div class="footer_logo col-xl-3 col-lg-3 col-md-3">
        <figure class="">
          <img src="assets/images/Logo/konarkit_transparent.png" alt="Footer" />
        </figure>
        <p>
          We can help our customers increase their business outputs through an
          application of the latest technical tools and a careful handling.
        </p>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-9">
        <ul class="row social-media">
          <li class="col">
            <a
              href="https://www.facebook.com/KonarkITofficial/?view_public_for=103837774845612"
              title="Facebook"
              target="_blank"
            >
              <i class="fa fa-facebook"></i>
            </a>
          </li>
          <li class="col">
            <a
              href="https://www.instagram.com/konarkit/
            "
              title="Instagram"
              target="_blank"
            >
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li class="col">
            <a
              href="https://twitter.com/KonarkIt"
              title="Twitter"
              target="_blank"
            >
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </li>

          <li class="col">
            <a
              href="https://www.linkedin.com/company/konarkit"
              title="Linkedin"
              target="_blank"
            >
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <ul class="row footer-menu">
          <li class="col">
            <a href="#" title="introduction">
              <h6>Home</h6>
            </a>
          </li>
          <li class="col">
            <a href="#about_us" title="About us">
              <h6>About us</h6>
            </a>
          </li>
          <li class="col">
            <a href="#solutions" title="Solutions">
              <h6>Solutions</h6>
            </a>
          </li>
          <li class="col">
            <a href="#career" title="Career">
              <h6>career</h6>
            </a>
          </li>
          <li class="col">
            <a href="#contact" title="Contact us">
              <h6>Contact us</h6>
            </a>
          </li>
        </ul>
        <ul class="row policy">
          <li class="col">
            <a href="#FIXME" title="Terms of use">
              <h6>Terms of Use</h6>
            </a>
          </li>
          <li class="col">
            <a href="#FIXME" title="Privacy Policy">
              <h6>Privacy Policy</h6>
            </a>
          </li>
          <!-- <li class="col">
            <a title="NHM" href="https://ultimateerp.in/NHM" target="_blank">
              <h6>Nhm</h6>
            </a>
          </li> -->
        </ul>
        <div class="row copy-right">
          <span>&copy; 2020 Konark It</span>
        </div>
      </div>
    </div>

    <!-- <ul class="row solution-menu">
      <li class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <a href="#section1" title="introduction">
          <h6>Mobile Application</h6>
        </a>
      </li>
      <li class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <a href="#section2" title="Features">
          <h6>Web design</h6>
        </a>
      </li>
      <li class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <a href="#section3" title="About us">
          <h6>Ecommerce site</h6>
        </a>
      </li>
      <li class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <a href="#section4" title="Career">
          <h6>Inventory Management</h6>
        </a>
      </li>
      <li class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <a href="#section5" title="Get in touch">
          <h6>Data and Business analytic</h6>
        </a>
      </li>
      <li class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <a href="#section5" title="Get in touch">
          <h6>Enterprise resource planning</h6>
        </a>
      </li>
      <li class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <a href="#section5" title="Get in touch">
          <h6>Ask for solution</h6>
        </a>
      </li>
    </ul> -->

    <!-- <div class="row copyright">
      <div class="col-xl-12"></div>
    </div> -->
  </div>
</section>
<!-- cd-section -->
