<section id="about_us" class="cd-section about">
  <div class="container">
    <h2>About us</h2>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4">
        <figure>
          <img src="assets/images/about_us.jpg" alt="About us" />
        </figure>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-8 col">
        <p>
          KONARK IT, founded by Team of IT & finance professional, who has rich
          experience in the field of IT & Finance from last 15-20 years. Konark
          IT has expertise in delivering solutions for ERP, Inventory
          management, SAP implementation and supports, Data & Business
          Analytical, Data Design management, Data Migration, Web Design and
          maintenance, App developments and customize IT solutions.
        </p>
        <p>
          Konark IT’s unique quality of understanding the business, operation,
          Processes, Commerce, User friendly Design, Scalable Architecture, Data
          perfection, smooth implementation and hand holding makes our solutions
          perfect for client business. Ultimately, Konark IT is all about<span
            class="think_text"
            >"Think solutions for you"</span
          >.
        </p>
        <p>
          We can help our customers increase their business outputs through an
          application of the latest technical tools and a careful handling of
          the changing dynamic of offshore software development and quality
          service to customers to conduct their businesses even more creatively
          and effectively. We balance project duration and product development
          efforts to suit your business needs.
        </p>

        <div class="why-us">
          <h3>Why us ?</h3>
          <p>
            As we say Think solution for you Konark IT here willing to give you
            solution to bring Growth, Profitability, Cost saving, Integration,
            Processes, Automation in your business and operations. Are you
            looking to transform your business driven by technology and bring
            value to your business. We are here to do for you.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container technology">
    <h2>Technology we used</h2>
    <div class="technology-content">
      <ul class="row">
        <li class="col-md-2 col-xs-6 col-md-offset-1 tech">
          <figure>
            <img src="assets/images/technology/php.png" alt="Php" />
          </figure>
          <span>php</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/angularjs.png" alt="AngularJs" />
          </figure>
          <span>angular Js</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/html-5.png" alt="Html5" />
          </figure>
          <span>html5</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/css3.png" alt="Css3" />
          </figure>
          <span>css3</span>
        </li>
        <li class="col-xl-2 col-md-2 tech">
          <figure>
            <img src="assets/images/technology/shopify.png" alt="Shopify" />
          </figure>
          <span>shopify</span>
        </li>
        <li class="col-md-2 col-xs-6 col-md-offset-1 tech">
          <figure>
            <img src="assets/images/technology/wordpress.png" alt="Wordpress" />
          </figure>
          <span>Wordpress</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img
              src="assets/images/technology/microsoft-access.png"
              alt="Microsoft Access"
            />
          </figure>
          <span>MsAccess</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/mysql.png" alt="MySql" />
          </figure>
          <span>MySql</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/database.png" alt="MySql" />
          </figure>
          <span>Sql</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/asp.png" alt="Asp" />
          </figure>
          <span>Asp.Net</span>
        </li>
        <li class="col-md-2 col-xs-6 col-md-offset-1 tech">
          <figure>
            <img src="assets/images/technology/bootstrap.png" alt="Bootstrap" />
          </figure>
          <span>bootstrap</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/java.png" alt="Java" />
          </figure>
          <span>Java</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/react.png" alt="React" />
          </figure>
          <span>React Js</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/ionic.png" alt="Ionic" />
          </figure>
          <span>ionic</span>
        </li>
        <li class="col-md-2 col-xs-6 tech">
          <figure>
            <img src="assets/images/technology/node-js.png" alt="Node-js" />
          </figure>
          <span>Node Js</span>
        </li>
      </ul>
    </div>
  </div> -->
</section>
<!-- Technology We used Section Start Here-->
