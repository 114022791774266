import { Component, OnInit, Input, OnChanges, SimpleChanges, DoCheck, SimpleChange } from '@angular/core';
import { event } from 'jquery';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  @Input('message') public message;
  @Input('msgType') public msgType;
  @Input('event') public event;

  constructor() { }

  ngOnInit(): void {

  }

  ngDoCheck() {
  }

  ngOnChanges(change: SimpleChanges): void {
    var x = document.getElementById("snackbar");
    console.log(this.message, this.msgType, this.event);
    if (this.event != undefined && this.event != "" && this.event != null) {
      if (this.event.type == "submit" || this.event.type == "click" || this.event.type == "change") {
        x.className = "show";
      }

      if (this.event.displayValue != null) {
        x.className = "show";
      }
    }
    console.log(x.className);

    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 2900);
  }

}
