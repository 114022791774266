<section id="career" class="cd-section career">
  <div class="container">
    <div class="wrapper">
      <div class="row">
        <div class="col-xl-8 col-md-8">
          <h2>Career</h2>
          <p>
            KONARK IT, founded by Team of IT & finance professional, who has
            rich experience in the field of IT & Finance from last 15-20 years.
            Konark IT has expertise in delivering solutions of ERP, Inventory
            management, SAP implementation and supports, Data & Business
            Analytical, Data Design management, Data Migration, Web Design and
            maintenance, App developments and customize IT solutions.
          </p>
          <p>
            Konark IT, A Startup is looking for Young, Talented and Passionate
            IT Engineers, UI-UX Designer, Finance and Contents writers, who are
            willing to work on challenging projects and want to be part of
            success story of creating superb IT Solutions and Products.
          </p>
          <button id="myBtn" class="btn btn-primary submit">Apply</button>
          <!-- The Modal -->
          <div id="myModal" class="modal">
            <!-- Modal content -->
            <div class="modal-content">
              <span class="close">&times;</span>
              <div class="modal-header">
                <h6>Please Fill the Details</h6>
              </div>
              <div class="modal-body">
                <form
                  (ngSubmit)="onCareerDetailsSubmit()"
                  [formGroup]="careerForm"
                >
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Enter full Name"
                      formControlName="name"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      formControlName="mailId"
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">Mobile Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Enter Mobile Number"
                      formControlName="mobileNo"
                    />
                  </div>
                  <div class="form-group">
                    <label for="text_area">Message</label>
                    <textarea
                      name=""
                      id="text_area"
                      cols="30"
                      rows="2"
                      class="form-control"
                      formControlName="message"
                    >
Write a Message..</textarea
                    >
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlFile1">Upload Resume</label>
                    <input
                      type="file"
                      class="form-control-file"
                      id="exampleFormControlFile1"
                      (change)="selectFile($event)"
                    />
                  </div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3">
          <figure>
            <img src="assets/images/career_new.png" alt="Career" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</section>
