import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  FormControl,
  FormBuilder,
  FormsModule,
  Validators,
} from '@angular/forms';
import { mail } from '../shared/global/globalUrl';
import { KonarkServiceService } from '../shared/service/konark-service.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  enquiryForm: FormGroup;
  url = '';
  message = '';
  msgType = '';
  event: Event;
  constructor(
    private formBuilder: FormBuilder,
    private _data: KonarkServiceService
  ) {}

  ngOnInit(): void {
    $('.form-box-title').click((e) => {
      e.preventDefault();

      $('.form').toggle();
    });

    $('.close').click((e) => {
      e.preventDefault();
      $('.form').css('display', 'none');
    });

    $(function () {
      $(window).on('scroll', function () {
        if ($(window).scrollTop() > 400) {
          var header = $('header');
          $('header').addClass('headerposition');
          $('.header-social').css('display', 'none');
        } else {
          //remove the background property so it comes transparent again (defined in your css)
          $('header').removeClass('headerposition');
          $('.header-social').css('display', 'flex');
        }
      });
    });
    this.loadForm();
  }

  loadForm() {
    this.enquiryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      mailId: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'),
        ],
      ],
      mobileNo: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      message: ['', [Validators.required]],
    });
  }

  onFinalDetailsSubmit(e) {
    // console.log(this.enquiryForm.value);
    this.url = mail.mail;
    this._data.post(this.url, this.enquiryForm.value).subscribe(
      (res) => {
        if (res == true) {
          this.message =
            'Thank You for enquiry. Our team will get back to you.';
          this.msgType = 'S';
          this.event = e;
          this.enquiryForm.reset();
        } else {
          this.message = 'kindly check your mail id.';
          this.msgType = 'E';
          this.event = e;
        }
      },
      (error) => {
        console.log(error);
        this.message = 'kindly check your mail id.';
        this.msgType = 'E';
        this.event = e;
      }
    );
  }
}
