<!-- <section id="section2" class="cd-section">
  <div class="container features">
    <h2>
      Konark
      <h2 class="it-color">IT</h2>
      Solution
    </h2>
    <div class="cd-projects-container" #liContainer>
      <ul class="cd-projects-previews slider_cart">
        <li class="bg-loaded data_and_business" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/analytic.jpg"
                alt="Data and Business Analytic"
              />
            </figure>
            <div class="cd-project-title">
              <h3>data and business analytic</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P
                >Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded consultancy" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/consultancy.jpg"
                alt="Consultancy"
              />
            </figure>
            <div class="cd-project-title">
              <h3>IT consultancy</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded data_migration" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/data_migration.jpg"
                alt="Data Migration"
              />
            </figure>
            <div class="cd-project-title">
              <h3>Data migration</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded ecommerce hide" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/ecommerce.jpg"
                alt="Ecommerce"
              />
            </figure>
            <div class="cd-project-title">
              <h3>Ecommerce</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded erp_development hide" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img src="../../assets/images/service/Erp.jpg" alt="ERP" />
            </figure>
            <div class="cd-project-title">
              <h3>ERP development</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded sap hide" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img src="../../assets/images/service/sap.png" alt="SAP" />
            </figure>
            <div class="cd-project-title">
              <h3>SAP Implementaion and Support</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded warehouse hide" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/warehouse.jpg"
                alt="Warehouse"
              />
            </figure>
            <div class="cd-project-title">
              <h3>warehouse managment solution</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded web_development hide" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/web_development.jpg"
                alt="Web Development"
              />
            </figure>
            <div class="cd-project-title">
              <h3>web development</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded data_design hide" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/Data.jpg"
                alt="Data Design"
              />
            </figure>
            <div class="cd-project-title">
              <h3>data design managment</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
        <li class="bg-loaded placement hide" #bgloaded>
          <a href="#0">
            <div class="overlay"></div>
            <figure>
              <img
                src="../../assets/images/service/placement.jpg"
                alt="Placement"
              />
            </figure>
            <div class="cd-project-title">
              <h3>placement</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                mollitia, sequi labore corporis ipsa hic saepe voluptatum!
                Beatae, voluptatum hic.
              </p>
              <P>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Numquam doloremque quaerat neque omnis magnam quas dignissimos
                assumenda ipsum labore consectetur.
              </P>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                exercitationem error voluptatum modi possimus cum illum
                asperiores maiores ducimus laborum.
              </p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  -->

<!-- Client Review Div Start Here -->
<!-- <div class="container client-review-container">
    <h3>Testimoinal</h3>
    <div class="client-review">
      <div class="row">
        <div class="col-xl-8">
          <ul class="testimonal_slidebar">
            <li>
              <blockquote>
                Tushti ipsum dolor sit amet consectetur adipisicing elit. Illum
                laborum ullam aut sapiente possimus assumenda pariatur autem
                odio? Minus porro odio veniam numquam accusantium ut autem esse
                alias temporibus
              </blockquote>
              <h5>Tusti</h5>
              <span>owner</span>
            </li>
            <li class="hide_slider">
              <blockquote>
                Shankareshwar Lorem ipsum dolor sit, amet consectetur
                adipisicing elit. Officia iste accusantium ex eum quos numquam
                eveniet sunt dolorem non possimus dicta soluta magnam veritatis,
                rem odio. Pariatur officiis nam assumenda.
              </blockquote>
              <h5>Shankarehswar</h5>
              <span>owner</span>
            </li>
            <li class="hide_slider">
              <blockquote>
                Iball ipsum dolor sit amet consectetur adipisicing elit. Illum
                laborum ullam aut sapiente possimus assumenda pariatur autem
                odio? Minus porro odio veniam numquam accusantium ut autem esse
                alias temporibus
              </blockquote>
              <h5>Iball</h5>
              <span>owner</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div> -->
<!-- </section>  -->

<section id="section2" class="cd-section">
  <div class="container solution">
    <h2>Solutions we offer</h2>
    <p>
      Konark IT offers wide range of IT solutions covering every aspect of
      Business
    </p>
    <div class="container solution_content">
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#app_development" title="Application development">
            <figure>
              <img
                src="assets/images/features_icon/android-os.png"
                alt="Android"
              />
              <img src="assets/images/features_icon/ios-logo.png" alt="Ios" />
            </figure>
            <span>Mobile Application</span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#web_development" title="Web design">
            <figure>
              <img src="assets/images/features_icon/www.png" alt="Ecommerce" />
            </figure>
            <span>Web Design and Maintenance</span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <div class="overlay"></div>
          <a href="#ecommerce" title="Ecommerce">
            <figure>
              <img
                src="assets/images/features_icon/shopping-cart.png"
                alt="Shooping Cart"
              />
            </figure>
            <span>Ecommerce Site</span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#warehouse" title="Inventory management solution">
            <figure>
              <img src="assets/images/features_icon/truck.png" alt="Truck" />
            </figure>
            <span>Inventory Managment </span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#data_design" title="Database design managment">
            <figure>
              <img
                src="assets/images/features_icon/database.png"
                alt="DataBase"
              />
            </figure>
            <span>DataBase Managment</span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#data_migration" title="Data Migration">
            <figure>
              <img
                src="assets/images/features_icon/data-migration.png"
                alt="Data migration"
              />
            </figure>
            <span>Data Migration</span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#business_analytic" title="Data and business analytic solution">
            <figure>
              <img
                src="assets/images/features_icon/analysis.png"
                alt="Data analytic"
              />
            </figure>
            <span>Data and Business Analytic</span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#erp" title="Erp Development">
            <figure>
              <img src="assets/images/features_icon/erp-system.png" alt="Erp" />
            </figure>
            <span>ERP Development</span>
          </a>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3">
          <a href="#solution" title="Ask for solution">
            <figure>
              <img
                src="assets/images/features_icon/question.png"
                alt="Ask for solution"
              />
            </figure>
            <span>Ask for Solution</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
