<div>
  <app-header></app-header>
  <app-banner></app-banner>
  <!-- <app-what-we-do></app-what-we-do> -->
  <app-about-us></app-about-us>
  <app-services></app-services>
  <app-career></app-career>
  <app-contact-us></app-contact-us>
  <app-footer></app-footer>
</div>
<!-- <div *ngIf="location == '/Nhm'">
  <app-nhm></app-nhm>
</div> -->

<!-- <router-outlet></router-outlet> -->
