<app-header></app-header>
<app-banner></app-banner>
<section>
  <div class="container pb-4">
    <div class="list-group mb-4">
      <a
        href="https://ultimateerp.in/NHM"
        title="Nhm Upload Data"
        class="list-group-item list-group-item-action list-group-item-warning text-dark"
        target="_blank"
        >Nhm Upload Data</a
      >
      <a
        href="#FIXME"
        title="Nhm Training"
        class="list-group-item list-group-item-action list-group-item-warning text-dark"
        >Nhm Training</a
      >
    </div>
  </div>
</section>

<!-- <app-footer></app-footer> -->
